<template>
    <div>
        <div class="flex flex-wrap mb-6">
            <div
                v-for="(module,index) in modules"
                :key="index"
                class="module-tn-wrapper"
            >
                <modal-link
                    route="modules.show"
                    :params="{websiteId: $route.params.websiteId, pageSlug: $route.params.pageSlug, moduleName: module}"
                >
                    <img :src="getImageUrl(module)" />
                    <p class="text-xs font-bold mt-2">{{ module }}</p>
                </modal-link>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalLink from "@/components/ui/modals/ModalLink";

    export default {
        components: { ModalLink },
        mixins: [ ModalNavigation ],
        computed: {
            ...mapGetters({
                modules: 'widgets/getModules'
            })
        },
        methods: {
            getImageUrl(moduleName) {
                try {
                    return require('@/assets/modules/' + moduleName + '.png');
                }
                catch(err) {
                    return require('@/assets/modules/NA.png');
                }
            }
        }
    }
</script>
<style scoped>
    .module-tn-wrapper{
        @apply w-1/3 p-4 mb-4 text-center cursor-pointer;
    }
    .module-tn-wrapper:hover{
        background-color: var(--highlightColor100);
    }
    .module-tn-wrapper:focus{
        outline: var(--highlightColorBase) auto 1px;
    }
</style>